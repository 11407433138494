import { Component, OnInit } from '@angular/core';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { NgxSpinnerService } from 'ngx-spinner';
import { authConfig } from 'src/app/OAuth/oauth.config';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { MODULE } from 'src/app/constants/application-constants';
import { AESEncryptDecryptService } from 'src/app/services/aesencrypt-decrypt.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username: any;
  details: any;
  module = MODULE.Login

  visualIVRData: any;
  claims:any;

  authCode:''

  constructor(
    private rxStompService: RxStompService,
    private dataService: SharedDataService,
    public spinnerService:NgxSpinnerService,
    private authService: OAuthService,
    private modalService:DialogBoxService,
    private AESEncriptionService: AESEncryptDecryptService,
  ) {

  }

  ngOnInit(): void {

    console.log('Login Initialized')
    this.configOAuth()

    this.dataService.currentcallDetails.subscribe(details => this.details = details);
    this.dataService.currentUsername.subscribe(username => this.username = username);

    if(localStorage.getItem("lastLocation") != null || localStorage.getItem("lastLocation") != undefined){
      if(localStorage.getItem("lastLocation")?.endsWith("start")){
        console.log(this.details)
        this.spinnerService.show();
        localStorage.removeItem("lastLocation")
        this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'Start','TRANSFER', 'TRANSFER', 'TRANSFER', this.username, '');
      }
    }
    
    this.spinnerService.hide();
    // this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'EnterLogin','startLogin', 'startLogin', 'SUCCESS', this.username, '');

  }

  configOAuth() {
    
    this.authService.configure(authConfig)
    // this.authService.loadDiscoveryDocument()
    // this.authService.setupAutomaticSilentRefresh()

  }

  
  private popupWindow: Window | null;

  onVerifyMe(): void {
    this.spinnerService.show();
    // this.authService.setupAutomaticSilentRefresh()
    this.popupWindow = window.open('')
    this.authService.initImplicitFlowInPopup({windowRef:this.popupWindow!})
    this.dataService.updatePopupWindow(this.popupWindow!)
    
    localStorage.removeItem("authCode")
    let cid=setInterval(()=>{
      if(this.rxStompService.connected()){
        let tid = setInterval(()=>{

          if(localStorage.getItem("authCode") != null){
            this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'Login', this.AESEncriptionService.encrypt(localStorage.getItem("authCode")!), 'finishLogin', 'CONTINUE', this.username, '');
            localStorage.removeItem("authCode")
            clearInterval(tid)
          }
        }, 500)
        clearInterval(cid)
      }
    }, 200)

    // this.authService.silentRefreshRedirectUri = window.location.origin + '/src/silent-refresh.html';
    // this.authService.silentRefresh()
    // .then(info => console.debug('refresh ok', info))
    // .catch(err => console.error('refresh error', err));
    // this.authService.login();
  }

  onCancel(){
    this.modalService.open(ConfirmDialogComponent, 'Login', this.details, this.username)
    // this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'Login','TRANSFER', 'TRANSFER', 'TRANSFER', this.username, '');
  }

  closeWindow(){
    this.popupWindow?.close()
  }
  
  handleMessage(event: MessageEvent) {
    if (event.data === 'closePopup') {
      window.close();
    }
  }

  // get token(){
  //   this.claims = this.authService.getIdentityClaims()
  //   if(this.claims){
  //     console.log('194: ', this.claims)
  //   }
  //   return this.claims?this.claims:null
  // }
}
