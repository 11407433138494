<div class="container-fluid p-0">

    <div class="d-border-bottom">
        <div class="mx-3 my-2 d-flex justify-content-end" >
            <app-language-dropdown [module]="module"></app-language-dropdown>
        </div>
    </div>
    
    <div class="d-border-bottom">
        <div class="p-3" style="position: relative;">
            <label class="form-label" class="d-header-label">
                {{'StartHead' | propValue | uppercase}}
            </label>
        </div>
    </div>

    <div class="p-3">

        <div class="mt-3 form-group">

            {{'StartParaText1' | propValue }} {{sundayMonth | propValue}} {{sundayDay}} {{sundayYear}}
            {{'StartParaText2' | propValue }} {{saturdayMonth  | propValue}} {{saturdayDay}} {{saturdayYear}}.
            {{'StartParaText3' | propValue }}

        </div>

        <div class="my-4 d-flex justify-content-between">
            <button type="button" class="flex-fill" [class]="btnDesable?'btn btn-continue btn-continue-desable':'btn btn-continue'"  (click)="gotoIncome()">
                {{'btnContinue' | propValue | uppercase}}
            </button>
            <!-- <button type="button" [class]="btnDesable?'btn btn-back btn-back-desable':'btn btn-back'" (click)="gotoLogin()">
                {{'btnBack' | propValue | uppercase}}
            </button> -->
            <button type="button" [class]="btnDesable?'btn btn-cancel btn-cancel-desable':'btn btn-cancel'" (click)="endCall()">
                {{'btnCancel' | propValue | uppercase}}
            </button>
        </div>
    </div>

    <!-- <confirm-dialog></confirm-dialog> -->
</div>